import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="9zy321jeew"
      title="Back-to-School With Rhombus: Assessing Your Physical Security"
      description="In this webinar, we walk through how to assess campus safety, and modern solutions that make it easier for schools create a safe environment for students and faculty in both the short and long term."
    />
  );
}
